@import '../../../../mac_base/scss/theme-bootstrap';

.lc_service_drawer {
  &-v2 {
    display: none;
    width: 400px;
    z-index: 900;
    background: $color--white;
    box-shadow: 0 0 4px $color--black;
    position: fixed;
    bottom: calc(60px + 4em);
    #{$rdirection}: 5px;
    @include breakpoint($bp--medium-down) {
      width: 100%;
      #{$rdirection}: 0;
      bottom: 0;
    }
  }
  &__header_container {
    background: $color--gray--black;
    padding: 15px;
  }
  &__header_text {
    color: $color--gray--lighter;
    margin: 0;
    float: #{$ldirection};
    font-size: 25px;
    text-transform: inherit;
  }
  &__close_icon {
    float: #{$rdirection};
    color: $color--gray--dusty;
    font-size: 29px;
    cursor: pointer;
    &:hover {
      color: $color--white;
    }
  }
  &__button {
    width: 100%;
    display: block;
    background: $color--gray--black;
    color: $color--white;
    text-align: center;
    padding: 15px 10px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 2em;
  }
  &__body_container {
    padding: 30px;
  }
  &__logo {
    width: 8em;
    height: 2em;
    float: #{$rdirection};
    fill: $color--white;
  }
  &__overlay {
    position: fixed;
    top: 0;
    display: none;
    @include breakpoint($bp--medium-down) {
      width: 100%;
      height: 100%;
      z-index: 899;
    }
  }
  &__wrapper {
    .lc_service_drawer__body_container a {
      color: $color--white;
    }
  }
}

.rounded-live-chat-button {
  border-radius: 2em;
  padding: 1em;
  cursor: pointer;
  z-index: 200;
  position: fixed;
  bottom: 60px;
  #{$rdirection}: 5px;
  background-color: $color--black;
  color: $color--white;
  display: none;
  justify-content: center;
  align-items: center;
  &__down-charot {
    border-bottom: 2px solid $color--white;
    border-#{$rdirection}: 2px solid $color--white;
    width: 0.5em;
    height: 0.5em;
    transform: rotate(45deg);
    margin: 0 10px 5px;
  }
}

.LPMcontainer {
  visibility: hidden;
}

#toky_container {
  img {
    display: none;
  }
}
